import React, { useState, useEffect } from 'react';
import { FaSync } from 'react-icons/fa';
import { getFrame } from '../../services/ImageService';
import './FrameViewer.css';

const FrameViewer = ({ frames, onConfirm, onBack, videoId, modelId, isWebSocket }) => {
    const [frameData, setFrameData] = useState(frames);
    const [loadingFrames, setLoadingFrames] = useState(new Array(frames.length).fill(false)); // Array to track loading state

    useEffect(() => {
        if (!isWebSocket) {
            setFrameData(frames);
        }
    }, [frames, isWebSocket]);

    useEffect(() => {
        if (isWebSocket) {
            // Initialize or update frames based on unique `position`
            setFrameData((prevData) =>
                frames.map((newFrame) => {
                    const existingFrame = prevData.find((frame) => frame.position === newFrame.position);
                    if (existingFrame) {
                        // Merge updated frame data
                        return {
                            ...existingFrame,
                            ...newFrame,
                        };
                    }
                    return newFrame; // Add new frame if not present
                })
            );
        }
    }, [frames, isWebSocket]);

    const handleCaptionChange = (index, value) => {
        const newFrameData = [...frameData];
        const lastTakeIndex = (newFrameData[index]?.takes || []).length - 1;
        if (lastTakeIndex >= 0) {
            newFrameData[index].takes[lastTakeIndex].caption = value; // Update the caption for the last take
        }
        setFrameData(newFrameData);
    };

    const handleRefresh = async (index) => {
        const newLoadingFrames = [...loadingFrames];
        newLoadingFrames[index] = true; // Set loading state to true
        setLoadingFrames(newLoadingFrames);

        const frame = frameData[index];
        const lastTakeIndex = (frame.takes || []).length - 1;
        const currentCaption = frame.takes?.[lastTakeIndex]?.caption || ''; // Use the caption from the last take
        const newFrameResponse = await getFrame(videoId, frame.position, currentCaption, modelId);

        if (newFrameResponse?.frame) {
            const newTake = {
                id: newFrameResponse.frame.takes[0].id, // Take ID from backend
                url: newFrameResponse.frame.takes[0].url, // URL from backend
                caption: currentCaption,
                created_at: new Date().toISOString(), // Add a timestamp
            };

            const newFrameData = [...frameData];
            if (newFrameData[index]) {
                // Append the new take to the `takes` array
                newFrameData[index].takes = [...(newFrameData[index].takes || []), newTake];
            }

            setFrameData(newFrameData);
        }

        newLoadingFrames[index] = false; // Set loading state to false
        setLoadingFrames(newLoadingFrames);
    };

    return (
        <div className="frame-viewer">
            <div className="frame-grid">
                {frameData.map((frame, index) => {
                    const lastTakeIndex = (frame.takes || []).length - 1; // Get the last take index
                    const lastTake = frame.takes?.[lastTakeIndex] || {}; // Fallback to an empty object

                    return (
                        <div key={frame.id} className="frame-item">
                            {lastTake.url ? (
                                <img src={lastTake.url} alt={`Frame ${index + 1}`} />
                            ) : (
                                <div className="loading-placeholder animate-pulse">Loading...</div>
                            )}
                            <div className="frame-caption-container">
                                <textarea
                                    className="frame-caption"
                                    value={lastTake.caption || ''}
                                    onChange={(e) => handleCaptionChange(index, e.target.value)}
                                    rows={3}
                                />
                                <button
                                    onClick={() => handleRefresh(index)}
                                    className="refresh-btn"
                                    disabled={loadingFrames[index]}
                                >
                                    <FaSync className={loadingFrames[index] ? 'spinning' : ''} />
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="button-container">
                <button className="back-btn" onClick={onBack}>Another Aesthetic</button>
                <button className="confirm-btn" onClick={onConfirm}>Confirm Frames</button>
            </div>
        </div>
    );
};

export default FrameViewer;